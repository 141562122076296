import createMiddleware from 'pmt-modules/redux/createMiddleware'

import { PatchProNotificationSettingAction, PostProNotificationsAction } from './actions'
import { getRoute, redirectTo } from 'pmt-modules/routing'

/**
 *
 */
const createdProNotificationSuccessMiddleware = createMiddleware(
  PostProNotificationsAction.SUCCESS,
  ({ action, dispatch, next }) => {
    dispatch(
      redirectTo(getRoute('PRO_NOTIFICATIONS_SETTINGS_EDIT'), {
        proNotificationSettingId: action.response.id,
      })
    )
  }
)

/**
 * Intercepts when a notification is updated, and redirect to the notification list page
 */
const updateProNotificationSuccessMiddleware = createMiddleware(
  PatchProNotificationSettingAction.SUCCESS,
  ({ dispatch }) => {
    dispatch(redirectTo(getRoute('PRO_NOTIFICATIONS_SETTINGS_LIST'), {}))
  }
)

export const proNotificationsSettingsMiddlewares = [
  createdProNotificationSuccessMiddleware,
  updateProNotificationSuccessMiddleware,
]
